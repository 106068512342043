import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import AppEmitter from '../../services/appEmitter';
import { RootState } from '../../store';
import { fetchCurrentHolds, fetchCurrentRegistrations, fetchEventTypes } from '../../store/User/UserSlice';
import { useInterval } from '../../utils/useInterval';
import './Header.css'

//const maxRetries = 3;

const UserPanel: React.FC = () => {
    const { userProfile } = useSelector((state: RootState) => state.user);
    const { user, isAuthenticated, logout, getIdTokenClaims } = useAuth0();
    const [showUserPanel, setShowUserPanel] = useState(false);

    const dispatch = useDispatch();

    const onLogout = () => {
        logout({
            returnTo: process.env.REACT_APP_ROOT_URL
        });
    };

    useEffect(() => {
        async function getIdToken() {
            let c = await getIdTokenClaims();
            if (c) {
                localStorage.setItem('idToken', c.__raw as string);
            }
        }

        if (user) {
            getIdToken();
            dispatch(fetchCurrentRegistrations(user));
            dispatch(fetchCurrentHolds(user));
            dispatch(fetchEventTypes(user));
        }

        AppEmitter.on('SHOW_USER_PANEL', (value: Boolean) => {
            setShowUserPanel(value as any);
        });

        AppEmitter.on('APPLICATION_AXIOS_AUTH_ERROR', async (payload: any) => {
            await getIdToken();
        });
        AppEmitter.on('APPLICATION_AXIOS_AUTH_ERROR_DONE', async (payload: any) => {
            onLogout();
        });
        // eslint-disable-next-line
    }, [user]);

    useInterval(
        () => {
            // Poll for changes.
            dispatch(fetchCurrentRegistrations(user));
        },
        10000
    )

    useInterval(
        () => {
            // Poll for changes.
            dispatch(fetchCurrentHolds(user));
        },
        10000
    )

    const getInitials = (name : string) => {
        if (name.split(' ').length > 1) {
            return name.split(' ')[0].slice(0)[0] + '' + name.split(' ')[1].slice(0)[0];
        } else {
            return name.split(' ')[0].slice(0)[0]
        }
    }

    return (
        isAuthenticated
            ?
            <div id='kt_quick_user' className={'offcanvas offcanvas-right p-10 ' + (showUserPanel ? 'offcanvas-on' : '')}>
                <div className='offcanvas-header d-flex align-items-center justify-content-between pb-5'>
                    <h3 className='font-weight-bold m-0 noto' style={{fontSize: 'large'}}>
                        User Profile
                        {/* <small className='text-muted font-size-sm ml-2'>12 messages</small> */}
                    </h3>
                    <a
                        data-testid='quick_user_close'
                        id='kt_quick_user_close'
                        className='btn btn-xs btn-icon btn-light btn-hover-primary'
                        onClick={() => setShowUserPanel(false)}>
                        <i className='ki ki-close icon-xs text-muted'></i>
                    </a>
                </div>
                <div className='offcanvas-content pr-5 mr-n5'>
                    <div className='d-flex align-items-center mt-5'>
                        <div className='symbol symbol-100 mr-5'>
                            {/* <div className='symbol-label' style={{ backgroundImage: `url('${user?.picture}')` }}></div> */}
                            { (user?.name != "") &&
                                <div className="circle noto">{getInitials(user?.name ?? "")}</div>
                            }
                        </div>
                        <div className='text-left'>
                            {
                                userProfile && userProfile.firstName
                                    ?
                                    <a className='noto font-weight-bold font-size-h5 text-dark-75 text-hover-primary'>{userProfile.firstName} {userProfile.lastName}</a>
                                    :
                                    <a className='noto font-weight-bold font-size-h5 text-dark-75 text-hover-primary'>{user?.name}</a>
                            }
                            <div className='navi mt-2'>
                                <span className='navi-link p-0 pb-2'>
                                    <span className='navi-icon mr-1'>
                                        <span className='svg-icon svg-icon-lg svg-icon-primary'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px'
                                                viewBox='0 0 24 24' version='1.1'>
                                                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                    <rect x='0' y='0' width='24' height='24' />
                                                    <path d='M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z'
                                                        fill='#000000' />
                                                    <circle fill='#000000' opacity='0.3' cx='19.5' cy='17.5' r='2.5' />
                                                </g>
                                            </svg>
                                        </span>
                                    </span>
                                    <span className='noto navi-text text-muted text-hover-primary'>{user?.email}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='navi mt-8'>
                            <Link
                                data-testid='logout_button'
                                to='/logout'
                                className='noto btn btn-sm btn-light-primary font-weight-bolder py-2 px-5'
                                type='a'
                            >Sign Out</Link>
                        </div>
                    </div>
                    <div className='separator separator-dashed mt-8 mb-5'></div>
                </div>
            </div>
            :
            <Fragment></Fragment>
    );
};

export default UserPanel;
